import { gql, useQuery } from "@apollo/client";
import React from "react";
import Loader from "./Loader";
import RuntimeProductImage from "./RuntimeProductImage";
import { shuffle } from "../utils/shuffleAlgorithm";

const RELATED_PRODUCTS = gql`
  query RELATED_PRODUCTS($relatedProducts: String, $sortKey: ProductSortKeys) {
    products(first: 250, query: $relatedProducts, sortKey: $sortKey) {
      nodes {
        id
        title
        description
        vendor
        productType
        handle
        availableForSale
        compareAtPriceRange {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        featuredImage {
          altText
          originalSrc
          width
          height
          url
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
`;

const RelatedProducts = ({productType, vendor, productId, client}) => {
  const { data, loading } = useQuery(RELATED_PRODUCTS, {
    variables: {
        query: `${productType} OR ${vendor}`,
    },
    fetchPolicy: "no-cache",
    onError: (error) => {
        console.log(error.message)
    }
  });
  const similar = [];
  if (!loading) {
data?.products.nodes.forEach((product) => {
    if (
      (product.vendor === vendor || product.productType === productType) &&
      product.id !== productId
    )
      similar.push(product);
  });
shuffle(similar)
  }

  return loading ? (
    <Loader />
  ) : (
    <aside aria-label="Related products" className="py-8 lg:py-24 bg-gray-200">
      <div className="px-4 mx-auto max-w-screen-xl">
        {similar.length > 0 ? (
          <h3 className="mb-8 text-2xl font-bold text-gray-900">
            You might also like
          </h3>
        ) : (
          <h3 className="mb-8 text-2xl text-center font-bold text-gray-900">
            No similar products are available
          </h3>
        )}
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
          {similar &&
            similar.slice(0, 4).map((product) => (
              <article className="max-w-full sm:max-w-xs" key={product.id}>
                <a
                  href={`/catalog/${product.handle}`}
           
                >
                  <RuntimeProductImage
                    className="rounded-t-lg"
                    storefrontProduct={product.featuredImage}
                    alt="Image"
                  />
                </a>
                <h4 className="my-2 text-xl font-bold leading-tight text-gray-900">
                  <a  href={`/catalog/${product.handle}`}>{product.title}</a>
                </h4>
                <p className="mb-4 font-light text-gray-900">
                  {product.description}
                </p>
                <a
                  href={`/catalog/${product.handle}`}

                  className="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 hover:no-underline"
                >
                  View
                </a>
              </article>
              
            ))}
        </div>
      </div>
    </aside>
  );
};

export default RelatedProducts;
