import React, { useContext, useState } from "react";
import { StoreContext } from "../context/store-context";
import * as Scroll from "react-scroll";

const AddToCart = ({ variantId, available }) => {
  const { addVariantToCart, loading } = useContext(StoreContext);
  const [quantity, setQuantity] = useState(1);

  const scroll = Scroll.animateScroll;
 
  function addToCart(e) {
      scroll.scrollToTop({
        smooth: true,
        delay: 100,
        duration: 500,
      });
    
    e.preventDefault();
    addVariantToCart(variantId, quantity); 
    
  }
  return (
    <>
      <div className="flex items-center mt-4">
        <button
          onClick={(e) => {
            e.preventDefault();
            setQuantity((q) => Math.max(1, q - 1));
          }}
          className="inline-flex items-center p-1 text-sm font-medium text-gray-500 bg-white rounded-full border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
          type="button"
        >
          <span className="sr-only">Quantity button</span>
          <svg
            className="w-4 h-4"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div>
          <input
            onChange={(e) => setQuantity(e.currentTarget.value)}
            value={quantity}
            type="number"
            onInput={(e) => (e.target.value = e.target.value.slice(0, 3))}
            className="bg-gray-50 w-14 border mx-2 border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1"
            required
          />
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            setQuantity((q) => Math.min(q + 1, 99));
          }}
          className="inline-flex items-center p-1 text-sm font-medium text-gray-500 bg-white rounded-full border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
          type="button"
        >
          <span className="sr-only">Quantity button</span>
          <svg
            className="w-4 h-4"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <button
        className="mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        onClick={(e) => {
          addToCart(e);
          
        }}
        disabled={
          available === "false" || available === false ? true : false || loading
        }
      >
        {available === "true" || available === true
          ? "Add to Cart"
          : "Out of Stock"}
      </button>
    </>
  );
};
export default AddToCart;
