import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify"
import image from "../images/placeholder.jpeg";

const RuntimeProductImage = ({ storefrontProduct }) => {
    const gatsbyImageData = getShopifyImage({
      image: storefrontProduct,
     
    });

      
        
  
    return (
      <GatsbyImage
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        image={gatsbyImageData}
        alt={storefrontProduct.altText ? storefrontProduct.altText : ""}
        className="w-full max-w-full h-[250px]"
      />
    );
}


export default RuntimeProductImage;