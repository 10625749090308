import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify";

const RuntimeDetailImage = ({ storefrontProduct }) => {
  const gatsbyImageData = getShopifyImage({
    image: storefrontProduct,
  });

  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt={storefrontProduct.altText ? storefrontProduct.altText : ""}
      className="lg:w-1/2 w-full max-h-[352px] object-center rounded border border-gray-200"
    />
  );
};

export default RuntimeDetailImage;
