import React, {useState} from 'react'
import AddToCart from './AddToCart';
import { formatPrice } from '../utils/formatPrice';

const VariantFilter = ({data}) => {

    const [variantID, setVariantID] = useState(data?.product?.variants.nodes[0].id);
    const [variantPrice, setVariantPrice] = useState({
        amount: data?.product?.variants?.nodes[0]?.priceV2?.amount,
        currencyCode: data?.product?.variants?.nodes[0]?.priceV2?.currencyCode,
    });
    const [available, setAvailable] = useState(data?.product?.variants.nodes[0].availableForSale);

  return (
    <>
    <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
    <div className="flex items-center">
      <span className="mr-3">Option</span>
      <div className="relative">
        <select
          onChange={(e) => {
            setVariantID(e.target.value);

            setVariantPrice({
              amount:
                e.target.childNodes[
                  e.target.selectedIndex
                ].getAttribute("data-price"),
              currencyCode:
                e.target.childNodes[
                  e.target.selectedIndex
                ].getAttribute("data-code"),
            });
            setAvailable(e.target.childNodes[
                e.target.selectedIndex
              ].getAttribute("data-available"))
          }}
          className="rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-blue-500 text-base pl-3 pr-10"
        >
          {data?.product?.variants?.nodes.map(
            ({
              title,
              id,
              availableForSale,
              priceV2,
              quantityAvailable,
            }) => (
              <option
                value={id}
                key={id}
                data-available={availableForSale}
                data-quantity={quantityAvailable}
                data-price={priceV2.amount}
                data-code={priceV2.currencyCode}
              >
                {title}
              </option>
            )
          )}
        </select> 
        <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-4 h-4"
            viewBox="0 0 24 24"
          >
            <path d="M6 9l6 6 6-6"></path>
          </svg>
        </span>
      </div>
    </div>
  </div>
  <div className="flex justify-between flex-wrap -mt-4">
    <span className="mt-4 title-font font-medium text-2xl text-gray-900">
      {formatPrice(
        data?.product?.variants.nodes[0].priceV2.currencyCode,
        data?.product?.variants.nodes[0].priceV2.amount
      )}
    </span>

    <AddToCart variantId={variantID} available={available} />
  </div>
  </>
  )
}

export default VariantFilter